<template>
  <v-container grid-list-md pa-0 relative class="page-layout">
    <v-layout row wrap>
      <v-flex xs12>
        <v-card>
          <v-card>
            <v-card-title class="title">
              &nbsp; New Purchase Items
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card outlined>
              <div class="bill-no" style="margin-top: 10px">
                <h3>Bill NO: {{ billNumber }}</h3>

                <span v-if="manualSetting">
                  <v-text-field
                    style="font-size: 20px"
                    label="Voucher No"
                    v-model="form.voucher_no"
                    name="voucher_no"
                    :error-messages="form.errors.get('voucher_no')"
                    :height="25"
                    class="pa-1"
                    :readonly="form.loading"
                  />
                </span>
                <span v-else class="voucher-no">
                  {{
                    automaticVoucherNo ? 'V.No: ' + automaticVoucherNo : ''
                  }}</span
                >
                <!-- <span class="voucher-no">V.No: {{ this.form.voucher_no }}</span> -->
                <!-- </v-flex> -->
              </div>

              <v-card-title>
                <v-flex xs4>
                  <v-select
                    outlined
                    dense
                    :readonly="form.loading"
                    v-model="form.voucher_type_id"
                    :items="voucherTypes"
                    class="pa-1"
                    label="Select Voucher Type"
                    name="voucher_type_id"
                    :height="25"
                  />
                  <span
                    v-if="form.voucher_type_id"
                    style="
                      position: absolute;
                      margin-top: -18px;
                      font-size: 12px;
                      color: green;
                    "
                  >
                    <span v-if="manualSetting"
                      >Voucher No should be entered manually.</span
                    >
                    <span v-else>Voucher No generate Automatically.</span>
                  </span>
                </v-flex>

                <v-flex xs4>
                  <v-autocomplete
                    outlined
                    dense
                    v-model="form.ledger_head_id"
                    :items="ledgers"
                    :search-input.sync="searchL"
                    hide-no-data
                    item-text="name"
                    item-value="id"
                    label="Account Ledger(Creditor)"
                    placeholder="Start typing to Search"
                    class="pa-1"
                    persistent-hint
                    :hint="ledger_opening"
                    :height="25"
                  ></v-autocomplete>
                </v-flex>
                <v-flex xs4>
                  <v-calendar-field
                    outlined
                    dense
                    label="Bill Date"
                    v-model="form.bill_date"
                    prepend-icon="event"
                    class="pa-1"
                    id-val="today-pi-1"
                  />
                </v-flex>
                <v-flex xs3>
                  <v-calendar-field
                    outlined
                    dense
                    class="pa-1"
                    id-val="today-pi-2"
                    v-model="form.purchase_bill_date"
                    label="Purchase Bill Date"
                    prepend-icon="event"
                  ></v-calendar-field>
                </v-flex>
                <v-flex xs3>
                  <v-text-field
                    outlined
                    dense
                    label="Purchase Bill No"
                    v-model="form.purchase_bill_no"
                    name="purchase_bill_no"
                    :error-messages="form.errors.get('purchase_bill_no')"
                    :height="25"
                    class="pa-1"
                    :readonly="form.loading"
                  />
                </v-flex>

                <v-flex xs3>
                  <v-select
                    outlined
                    dense
                    :readonly="form.loading"
                    v-model="form.bill_type"
                    :items="billTypes"
                    class="pa-1"
                    label="Select Bill Type"
                    name="bill_type"
                    :height="25"
                  />
                </v-flex>
                <v-flex xs3>
                  <v-text-field
                    outlined
                    dense
                    :disabled="!form.bill_type || !form.purchase_bill_no"
                    label="Select Purchase Bill"
                    @click="onPickFile"
                    v-model="form.fileName"
                    append-icon="image"
                  ></v-text-field>
                  <input
                    type="file"
                    style="display: none"
                    ref="fileInput"
                    accept="image/*"
                    @change="onFilePicked"
                  />
                  <!-- <v-btn
                  color="primary"
                  @click.stop="onUploadSelectedFileClick"
                  :loading="loading"
                  >UPLOAD</v-btn -->
                  <!-- <v-icon
                    v-if="form.fileName"
                    right
                    small
                    @click="removeFile"
                    style="background: red; color: white"
                    >cancel</v-icon
                  >
                  
                  <v-btn
                    v-if="form.fileName"
                    color="primary"
                    @click.stop="viewImage"
                    small
                  >
                    VIEW
                  </v-btn> -->
                  <v-card v-if="form.fileName">
                    <v-card-text>
                      <img :src="url" height="100" width="75%" />
                      <v-btn
                        fab
                        small
                        bottom
                        right
                        absolute
                        @click.native.stop="removeFile"
                        style="z-index: 1"
                        color="red white--text"
                      >
                        <v-icon dark>remove</v-icon>
                      </v-btn>
                    </v-card-text>
                  </v-card>
                </v-flex>
              </v-card-title>
            </v-card>
          </v-card>
          <div class="itemWrap">
            <v-layout row wrap>
              <v-flex xs4>
                <v-autocomplete
                  outlined
                  dense
                  v-model="addItemData.item_id"
                  :items="items"
                  :search-input.sync="searchI"
                  hide-no-data
                  item-text="name"
                  item-value="id"
                  label="Item"
                  placeholder="Start typing to Search"
                  class="pa-1"
                  :height="25"
                ></v-autocomplete>
              </v-flex>
              <v-flex xs4>
                <v-select
                  outlined
                  dense
                  :readonly="form.loading"
                  v-model="addItemData.unit_id"
                  :items="units"
                  class="pa-1"
                  label="Unit"
                  name="unit_id"
                  :height="25"
                  :disabled="!addItemData.item_id"
                />
              </v-flex>
              <!-- <v-flex xs1>
                <div>
                  <add-unit-button></add-unit-button>
                </div>
              </v-flex> -->
              <v-flex xs3>
                <v-select
                  outlined
                  dense
                  :readonly="form.loading"
                  v-model="addItemData.warehouse_id"
                  :items="warehouse"
                  class="pa-1"
                  label="Warehouse"
                  name="warehouses_id"
                  :height="25"
                  :disabled="!addItemData.item_id"
                />
              </v-flex>
              <v-flex xs1>
                <div>
                  <add-ware-house-button
                    @onSuccess="getWarehouse"
                  ></add-ware-house-button>
                </div>
              </v-flex>
              <v-flex xs4>
                <v-select
                  outlined
                  dense
                  :readonly="form.loading"
                  v-model="addItemData.branch_id"
                  :items="branch"
                  class="pa-1"
                  label="Branch"
                  name="branch_id"
                  :height="25"
                  :disabled="!addItemData.item_id"
                />
              </v-flex>
              <v-flex xs1>
                <div>
                  <add-branch-button @onSuccess="getBranch"></add-branch-button>
                </div>
              </v-flex>
              <v-flex xs2>
                <v-text-field
                  outlined
                  dense
                  v-model="addItemData.qty"
                  name="qty"
                  label="Quantity"
                  class="pa-1"
                  :error-messages="form.errors.get('qty')"
                  :height="25"
                  :disabled="!addItemData.warehouse_id"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs2>
                <v-text-field
                  outlined
                  dense
                  v-model="addItemData.rate"
                  name="rate"
                  label="Rate"
                  class="pa-1"
                  :error-messages="form.errors.get('rate')"
                  :height="25"
                  :disabled="!addItemData.warehouse_id"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs2>
                <v-text-field
                  outlined
                  dense
                  v-model="addItemData.amount"
                  name="qty"
                  label="Amount"
                  class="pa-1"
                  :error-messages="form.errors.get('amount')"
                  :height="25"
                  :disabled="!addItemData.warehouse_id"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs2>
                <v-btn
                  v-if="update"
                  style="margin-top: 10px"
                  color="success"
                  outlined
                  dense
                  text
                  @click="updateItem(addItemData), discountPercentage()"
                  :disabled="
                    !(
                      addItemData.warehouse_id &&
                      addItemData.rate &&
                      addItemData.qty &&
                      addItemData.amount
                    )
                  "
                >
                  Update
                </v-btn>
                <v-btn
                  v-else
                  style="margin-top: 0px"
                  color="success"
                  outlined
                  text
                  @click="addItem(addItemData), discountPercentage()"
                  :disabled="
                    !(
                      addItemData.warehouse_id &&
                      addItemData.rate &&
                      addItemData.qty &&
                      addItemData.amount
                    )
                  "
                >
                  OK
                </v-btn>
              </v-flex>
              <div v-if="addItemData.item_id">
                <span class="span-title">Purchase Rate:</span>
                <span class="span-qty">{{ addItemData.purchase_rate }}</span>
              </div>
              <div v-if="addItemData.warehouse_id">
                <span class="span-title">Stock Quantity:</span>
                <span class="span-qty">{{ stock_qty }}</span>
              </div>
            </v-layout>
          </div>
          <v-data-table
            :headers="headers"
            :items="form.item_data"
            hide-default-footer
            class="tb"
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td>
                  <v-checkbox
                    primary
                    :input-value="item_data_checkbox.includes(item)"
                    @click.stop="selectItemDataCheckbok(item)"
                  >
                  </v-checkbox>
                </td>
                <td>{{ ++index }}</td>
                <td class="text-xs-left">
                  {{ item.item_name }}
                </td>
                <td class="text-xs-left">
                  <v-chip
                    v-if="item.is_taxable === 1"
                    color="green"
                    text-color="white"
                    class="chipH"
                  >
                    Yes
                  </v-chip>
                  <v-chip v-else color="red" class="chipH" text-color="white"
                    >No</v-chip
                  >
                </td>
                <td class="text-xs-left">
                  {{
                    findWareHouse(item.warehouse_id)
                      ? findWareHouse(item.warehouse_id).name
                      : ''
                  }}
                </td>
                <td class="text-xs-left">
                  {{
                    findBranch(item.branch_id)
                      ? findBranch(item.branch_id).name
                      : ''
                  }}
                </td>
                <td class="text-xs-left">{{ item.qty }}</td>
                <td class="text-xs-left">{{ item.rate }}</td>
                <td class="text-xs-left">{{ item.unit_name }}</td>
                <td class="text-xs-left">{{ item.amount }}</td>
                <td class="text-xs-left">
                  {{ item.is_taxable ? (item.amount * 13) / 100 : '-' }}
                </td>
                <td class="text-right">
                  <v-icon
                    small
                    @click="editItem(item, index)"
                    style="margin-right: 2px; background: blue; color: white"
                  >
                    edit
                  </v-icon>

                  <v-icon
                    small
                    @click="removeItem(item), removeItemCheckbok(item)"
                    style="background: red; color: white"
                    >clear
                  </v-icon>
                </td>
              </tr>
            </template>

            <template v-slot:footer v-if="form.item_data.length">
              <v-card xs12>
                <div style="width: 100%">
                  <div style="width: 50%; float: left">
                    <br />
                    <div
                      style="float: left; margin-left: 10px; margin-top: 5px"
                    >
                      <strong> Add Additonal Information</strong>
                    </div>
                    <div style="float: right; margin-right: 130px">
                      <v-btn @click="addRow" outlined>
                        <v-icon small class="mr-2"> add</v-icon>
                      </v-btn>
                    </div>
                    <br />
                    <br />
                    <v-flex xs12>
                      <v-layout
                        row
                        wrap
                        v-for="(row, key) in form.additional_data"
                        :key="key"
                      >
                        <v-flex xs5>
                          <v-text-field
                            v-model="row.title"
                            outlined
                            dense
                            name="title"
                            label="Title"
                            class="pa-1"
                            :height="25"
                            autocomplete="off"
                          >
                          </v-text-field>
                        </v-flex>
                        <v-flex xs5>
                          <v-text-field
                            v-model="row.amount"
                            outlined
                            dense
                            name="amount"
                            label="Amount"
                            class="pa-1"
                            :height="25"
                            autocomplete="off"
                            type="number"
                          >
                          </v-text-field>
                        </v-flex>
                        <v-flex xs2>
                          <v-btn
                            style="margin-top: 6px"
                            v-if="!(form.additional_data.length < 2)"
                            @click="removeRow(row)"
                            outlined
                          >
                            <v-icon small class="mr-2"> remove</v-icon></v-btn
                          >
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </div>
                  <div style="width: 50%; float: right">
                    <v-flex xs12 style="margin-top: 8px">
                      <tr class="footerRow">
                        <td
                          :colspan="7"
                          class="text-xs-right"
                          style="width: 24%"
                        >
                          Sub Total:
                        </td>
                        <td
                          :colspan="5"
                          style="padding-bottom: 10px; padding-left: 20px"
                        >
                          {{ subTotalCompute }}
                        </td>
                      </tr>

                      <tr class="footerRow">
                        <td :colspan="7" class="text-xs-right">Dis %</td>
                        <td :colspan="2" class="text-xs-right">
                          <input
                            style="
                              height: 40px;
                              padding-left: 10px;
                              margin-bottom: 14px;
                              margin-left: 20px;
                            "
                            type="number"
                            class="discountText"
                            v-model="form.discount_in_percentage"
                            @keyup="discountPercentage"
                          />
                        </td>
                      </tr>
                      <tr class="footerRow">
                        <td :colspan="7" class="text-xs-right" width="20px">
                          Dis Amount:
                        </td>
                        <td :colspan="2">
                          <input
                            style="
                              height: 40px;
                              padding-left: 10px;
                              margin-bottom: 14px;
                              margin-left: 20px;
                            "
                            type="number"
                            class="discountText"
                            v-model="form.discount_in_amount"
                            @keyup="discountAmount"
                          />
                        </td>
                      </tr>
                      <!--                            <tr class="footerRow">-->
                      <!--                                <td :colspan="7" class="text-xs-right">Taxable Amount:</td>-->
                      <!--                                <td :colspan="2">{{taxable_amount_after_discount}}</td>-->
                      <!--                            </tr>-->
                      <!--                            <tr class="footerRow">-->
                      <!--                                <td :colspan="7" class="text-xs-right">Non Taxable Amount:</td>-->
                      <!--                                <td :colspan="2">{{non_taxable_amount_after_discount}}</td>-->
                      <!--                            </tr>-->
                      <tr class="footerRow">
                        <td :colspan="7" class="text-xs-right">VAT 13%:</td>
                        <td
                          :colspan="2"
                          style="
                            display: flex;
                            align-items: center;
                            padding: 0px 20px 10px;
                            margin-left: -3px;
                          "
                        >
                          <input
                            type="checkbox"
                            style="width: 20px; height: 14px; margin-right: 8px"
                            v-model="vatCheckBox"
                          />
                          {{ tax_amount }}
                        </td>
                      </tr>
                      <tr class="footerRow">
                        <td :colspan="7" class="text-xs-right">Total:</td>
                        <td :colspan="2" style="padding: 0px 20px 10px">
                          {{ totalCompute }}
                        </td>
                      </tr>
                      <tr class="footerRow">
                        <td valign="top">Narration:</td>
                        <td
                          :colspan="8"
                          width="100%"
                          style="padding-bottom: 10px"
                        >
                          <textarea
                            style="margin-left: 20px"
                            v-model="form.narration"
                            :rows="3"
                            class="footerTextarea"
                            :error-messages="form.errors.get('narration')"
                          ></textarea>
                        </td>
                      </tr>
                      <tr class="footerRowNarration">
                        <td :colspan="headers.length" class="text-xs-right">
                          <v-btn
                            style="margin-right: 20px"
                            color="warning"
                            outlined
                            text
                            @click="form.reset()"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            style="margin-right: 20px"
                            color="success"
                            outlined
                            text
                            @click="save(0)"
                            >Save</v-btn
                          >
                          <v-btn
                            style="margin-right: 20px"
                            color="info"
                            outlined
                            text
                            @click="save(1)"
                            >Save & Print</v-btn
                          >
                        </td>
                      </tr>
                    </v-flex>
                  </div>
                  <div style="clear: both"></div>
                </div>
              </v-card>
            </template>
          </v-data-table>
          <v-data-table
            :headers="purchaseDetails"
            :items="latestPurchaseItem"
            hide-default-footer
            class="tb"
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td>{{ ++index }}</td>
                <td>{{ item.voucher_type.name }}</td>
                <td>{{ item.bill_no }}</td>
                <td>{{ item.purchase_bill_no }}</td>
                <td v-for="(x, index) in item.items" :key="index" colspan="3">
                  <table style="width: 100%">
                    <tr>
                      <td>{{ x.item_name }}</td>
                      <td>{{ x.qty }}</td>
                      <td>{{ x.rate }}</td>
                    </tr>
                  </table>
                </td>
                <td>{{ item.discount_amount ? item.discount_amount : '-' }}</td>
                <td>{{ item.vat_amount ? item.vat_amount : '-' }}</td>
                <td>{{ item.total_amount }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import Form from '@/library/Form';
import { redirectDownloadUrl } from '@/library/helpers';
const NepaliDate = require('nepali-date');
const nd = new NepaliDate();
export default {
  data: () => ({
    loading: false,
    form: new Form(
      {
        bill_type: '',
        discount_in_percentage: 0,
        discount_in_amount: 0,
        bill_date: '',
        purchase_bill_no: '',
        purchase_bill_date: '',
        ledger_head_id: '',
        voucher_type_id: '',
        voucher_no: '',
        fileName: '',
        sub_total: 0,
        taxable_amount_after_discount: 0,
        non_taxable_amount_after_discount: 0,
        total_amount: 0,
        vat_amount: 0,
        narration: '',
        bill_img: '',
        checked_data: [],
        item_data: [
          // {
          //     warehouse_id: '',
          //     is_taxable: '',
          //     item_id: '',
          //     qty: '',
          //     rate: '',
          //     amount: '',
          //     item_name: '',
          //     warehouse_name: '',
          // }
        ],
        additional_data: [
          {
            title: '',
            amount: '',
          },
        ],
        notify: {
          min_qty: '',
          max_qty: '',
          reorder_level: '',
        },
      },
      '/api/account/inventory/purchase'
    ),
    vatCheckBox: false,
    searchI: null,
    billNumber: '',
    searchL: null,
    searchW: null,
    wareHouseSearchData: [],
    itemSearchData: [],
    ledgerSearchData: [],
    voucherTypes: [],
    manualSetting: false,
    automaticVoucherNo: '',
    addItemData: {
      warehouse_id: '',
      is_taxable: '',
      item_id: '',
      qty: '',
      rate: '',
      amount: '',
      item_name: '',
      warehouse_name: '',
      branch_id: '',
    },
    units: [],
    openings: [],
    sub_total: 0,
    taxable_amount: 0,
    non_taxable_amount: 0,
    tax_amount: 0,
    total_amount: 0,
    taxable_item_count: 0,
    non_taxable_item_count: 0,
    individual_discount: 0,
    taxable_amount_after_discount: 0,
    non_taxable_amount_after_discount: 0,
    editDataResetFlag: true,
    stock_qty: 0,
    ledger_opening: '',
    update: false,
    headers: [
      { text: ' ', align: 'left', sortable: false, value: 'id', width: '20px' },
      { text: '#', align: 'left', sortable: false, value: 'id', width: '20px' },
      { text: 'Item', value: 'item', align: 'left' },
      {
        text: 'Vatable',
        value: 'item',
        align: 'left',
        sortable: false,
        width: '20px',
      },
      { text: 'Ware House', value: 'warehouse', align: 'left' },
      { text: 'Branch', value: 'branch', align: 'left' },
      { text: 'Qty', value: 'qry', align: 'left', sortable: false },
      { text: 'Rate', value: 'rate', align: 'left', sortable: false },
      { text: 'Unit', value: 'unit', sortable: false, align: 'left' },
      { text: 'Amount', value: 'amount', sortable: false, align: 'left' },
      {
        text: 'Taxable Amount',
        value: 'tax_amount',
        sortable: false,
        align: 'left',
      },
      {
        text: 'Action',
        value: 'action',
        sortable: false,
        align: 'right',
        width: 120,
      },
    ],
    purchaseDetails: [
      { text: '#', align: 'left', sortable: false, value: 'id', width: '20px' },
      {
        text: 'Voucher Type',
        align: 'left',
        sortable: false,
        value: 'voucher_type',
      },
      {
        text: 'Bill No.',
        align: 'left',
        sortable: false,
        value: 'bill_no',
      },
      {
        text: 'Purchse Bill No.',
        align: 'left',
        sortable: false,
        value: 'bill_no',
      },
      {
        text: 'Item',
        align: 'left',
        sortable: false,
        value: 'item',
      },
      { text: 'Qty', value: 'qry', align: 'left', sortable: false },
      { text: 'Rate', value: 'rate', align: 'left', sortable: false },
      { text: 'Discount', value: 'discount', align: 'left', sortable: false },
      { text: 'Vat', value: 'vat', align: 'left', sortable: false },
      { text: 'Amount', value: 'amount', sortable: false, align: 'left' },
    ],
    billTypes: [
      { value: 'cash', text: 'Cash' },
      { value: 'credit', text: 'Credit' },
    ],
    warehouse: [],
    branch: [],
    fileObject: null,
    today: nd.format('YYYY-MM-DD'),
    warehouseData: [],
    branchData: [],
    latestPurchaseItem: [],
    selectedItem: [],
    item_data_checkbox: [],
    url: null,
  }),
  computed: {
    ...mapState(['batch']),
    items() {
      return this.itemSearchData.map((entry) => {
        return { name: entry.name, id: entry.id, data: entry };
      });
    },
    ledgers() {
      return this.ledgerSearchData.map((entry) => {
        return { name: entry.name, id: entry.id, data: entry };
      });
    },
    warehouses() {
      return this.warehouse.map((entry) => {
        return { name: entry.text, id: entry.value };
      });
    },
    subTotalCompute() {
      let sub_total_compute = 0;
      this.item_data_checkbox.map((res) => {
        sub_total_compute += parseFloat(res.amount);
      });
      return sub_total_compute;
    },
    totalCompute() {
      let total_amount = 0;
      let amountAfterDiscount;
      if (this.form.discount_in_percentage > 0) {
        amountAfterDiscount =
          this.subTotalCompute - this.form.discount_in_amount;
      } else {
        amountAfterDiscount = this.subTotalCompute;
      }
      if (this.vatCheckBox) {
        total_amount = amountAfterDiscount + this.tax_amount;
      } else {
        total_amount = amountAfterDiscount;
      }
      return total_amount;
    },
  },
  mounted() {
    this.getVoucherTypes();
    this.getBillDate();
    this.getWarehouse();
    this.getPurcaseItem();
    this.getBranch();
  },
  watch: {
    pagination: function () {
      this.get();
    },
    batch: function (value) {
      this.get();
    },
    'form.item_id': function (v) {
      let data = this.items.find((res) => res.id === v);
    },
    searchI(val) {
      if (!val) return;
      // if (this.isLoading) return;
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.isLoading = true;
        this.isLoading = true;
        this.$rest
          .get('api/account/inventory/item?search=' + val)
          .then(({ data }) => {
            this.itemSearchData = data.data.map((res) => {
              return { name: res.name, id: res.id, data: res };
            });
          })
          .catch((err) => {})
          .finally(() => (this.isLoading = false));
      }, 500);
    },
    searchL(val) {
      if (!val) return;
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.isLoading = true;
        this.$rest
          .get('api/ledger-head?search=' + val + '&type=creditors')
          .then(({ data }) => {
            this.ledgerSearchData = data.data.map((res) => {
              return { name: res.name, id: res.id, data: res };
            });
          })
          .catch((err) => {})
          .finally(() => (this.isLoading = false));
      }, 500);
    },
    searchW(val) {
      if (!val) return;
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.isLoading = true;
        // this.isLoading = true;
        this.$rest
          .get('api/account/inventory/warehouse?search=' + val)
          .then(({ data }) => {
            this.wareHouseSearchData = data.data.map((res) => {
              return { name: res.name, id: res.id };
            });
          })
          .catch((err) => {})
          .finally(() => (this.isLoading = false));
      }, 500);
    },
    'addItemData.item_id': function (v) {
      if (v) {
        let itemData = this.findItem(v);
        this.addItemData.is_taxable = itemData.data.is_taxable;
        if (!this.update) {
          this.units = [
            { value: itemData.data.unit_id, text: itemData.data.unit_name },
          ];
          this.addItemData.unit_id = itemData.data.unit_id;
          this.addItemData.unit_name = itemData.data.unit_name;
          this.addItemData.purchase_rate = itemData.data.purchase_rate;
        }
        if (this.editDataResetFlag) {
          this.addItemData.warehouse_id = '';
          this.editDataResetFlag = true;
        }
        if (!this.editDataResetFlag && this.update) {
          this.editDataResetFlag = true;
        }
        //
        // this.addItemData.qty = 0;
        this.stock_qty = 0;
      }
    },
    'addItemData.warehouse_id': function (v) {
      if (v) {
        let wData = this.wareHouseSearchData.find((res) => res.id === v);
        this.addItemData.warehouse_name = wData ? wData.name : '';
        if (this.addItemData.warehouse_id && this.editDataResetFlag) {
          this.$rest
            .get(
              '/api/account/inventory/stock/qty?itemId=' +
                this.addItemData.item_id +
                '&warehouseId=' +
                this.addItemData.warehouse_id
            )
            .then((res) => {
              this.stock_qty = res.data;
            });
        }
      }
      if (!this.editDataResetFlag && this.update) {
        this.addItemData.qty = 0;
        this.addItemData.rate = 0;
      }
    },
    // 'addItemData.unit_id': function (v) {
    //     let uData = this.unitsSearchData.findUnit(res => res.id === v);
    //     this.addItemData.unit_name = uData ? uData.name : '';
    // },
    'addItemData.rate': function (v) {
      if (v && this.addItemData.qty) {
        this.addItemData.amount =
          parseFloat(v) * parseFloat(this.addItemData.qty);
      } else {
        this.addItemData.amount = 0;
      }
    },
    'addItemData.qty': function (v) {
      if (v && this.addItemData.rate) {
        this.addItemData.amount =
          parseFloat(v) * parseFloat(this.addItemData.rate);
      } else {
        this.addItemData.amount = 0;
      }
    },
    'addItemData.amount': function (v) {
      if (v && this.addItemData.qty) {
        this.addItemData.rate =
          parseFloat(v) / parseFloat(this.addItemData.qty);
      }
    },
    'form.voucher_type_id': function (v) {
      let d = this.voucherTypes.filter((data) => {
        if (data.value == v) {
          return data;
        }
      });
      if (d.length && d[0].setting == 'manual') {
        this.manualSetting = true;
        this.form.voucher_no = '';
      } else {
        if (v) {
          this.$rest.get('/api/journal-type-voucher/' + v).then((data) => {
            this.automaticVoucherNo = data.data;
            this.form.voucher_no = this.automaticVoucherNo;
          });
        }
        this.manualSetting = false;
      }
    },
    'form.ledger_head_id': function (v) {
      this.$rest.get('/api/ledger-head/opening/' + v).then((res) => {
        if (res.data.length) {
          if (res.data > 0)
            this.ledger_opening =
              'Openinging Balance(Dr):' + Math.abs(res.data);
          else
            this.ledger_opening =
              'Openinging Balance(Dr):' + Math.abs(res.data);
        } else this.ledger_opening = 'Openinging Balance: 0';
      });
    },
    vatCheckBox: function (v) {
      this.getVatAmount();
    },
  },
  methods: {
    editData(data) {
      this.categorySearchData = [];
      this.categorySearchData.push({
        name: data.parent_name,
        id: data.parent_id,
      });
    },
    save(value) {
      this.form.bill_date = this.form.bill_date.dateForm();
      this.form.purchase_bill_date = this.form.purchase_bill_date.dateForm();
      this.form.total_amount = this.totalCompute;
      this.form.vat_amount = this.tax_amount;
      this.form.sub_total = this.subTotalCompute;
      this.form.taxable_amount_after_discount =
        this.taxable_amount_after_discount;
      this.form.non_taxable_amount_after_discount =
        this.non_taxable_amount_after_discount;
      this.form.additional_data = this.form.additional_data;
      this.form.checked_data = this.item_data_checkbox;

      this.form
        .store()
        .then((res) => {
          this.$events.fire('notification', {
            message: res.data.message,
            status: 'success',
          });
          if (value === 1) {
            redirectDownloadUrl({
              uri: '/download/purchase-item',
              queryString: `billNo=${this.billNumber}`,
            });
          }
          this.item_data_checkbox = [];
          this.form.additional_data = [];
          this.getBillDate();
          this.getVoucherTypes();
          this.getWarehouse();
          this.getPurcaseItem();
        })
        .catch((e) => {
          this.$events.fire('notification', {
            message: e.response.data.message,
            status: 'error',
          });
        });
    },
    searchItem: function (v) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.get();
      }, 500);
    },
    addItem(item) {
      if (item.warehouse_id && item.qty && item.rate) {
        if (item.is_taxable) {
          this.vatCheckBox = true;
        }
        item.warehouse_name = this.findWareHouse(item.warehouse_id);
        item.item_name = this.findItem(item.item_id).name;
        item.branch_name = this.findBranch(item.branch_id);
        this.form.item_data.push(item);
        this.item_data_checkbox.push(item);
        this.update = false;
        this.addItemdataReset();
        this.calculation();
      }
    },
    removeItem: function (item) {
      var index = this.form.item_data.indexOf(item);
      this.form.item_data.splice(index, 1);
      this.addItemdataReset();
      this.update = false;
    },
    removeItemCheckbok: function (item) {
      var index = this.item_data_checkbox.indexOf(item);
      this.item_data_checkbox.splice(index, 1);
      this.discountPercentage();
    },
    addRow: function () {
      if ([undefined, null].includes(this.form.additional_data)) {
        this.form.additional_data = [{ title: '', amount: '' }];
      } else {
        this.form.additional_data.push({ title: '', amount: '' });
      }
    },
    removeRow: function (row) {
      var index = this.form.additional_data.indexOf(row);
      this.form.additional_data.splice(index, 1);
    },
    editItem(item, index) {
      this.editDataResetFlag = false;
      this.update = true;
      this.editIndex = index - 1;
      this.addItemData.qty = item.qty;
      this.addItemData.rate = item.rate;
      this.addItemData.is_taxable = item.is_taxable;
      this.addItemData.amount = item.amount;
      this.addItemData.purchase_rate = item.purchase_rate;
      this.addItemData.warehouse_id = item.warehouse_id;
      this.addItemData.warehouse_name = item.warehouse_name;
      this.addItemData.branch_id = item.branch_id;
      this.addItemData.branch_name = item.branch_name;
      this.addItemData.unit_id = item.unit_id;
      this.addItemData.item_id = item.item_id;
      this.searchW = item.warehouse_name;
      this.wareHouseSearchData.push({
        name: item.warehouse_name,
        id: item.warehouse_id,
      });
      this.units = [{ text: item.unit_name, value: item.unit_id }];
      this.itemSearchData.push({ name: item.item_name, id: item.item_id });
    },
    updateItem() {
      this.fillAddItemData(this.editIndex, this.addItemData);
      this.update = false;
      this.addItemdataReset();
      this.calculation();
    },
    findWareHouse(warehouse_id) {
      return this.warehouseData.find((res) => res.id === warehouse_id);
    },
    findBranch(branch_id) {
      return this.branchData.find((res) => res.id === branch_id);
    },
    findUnit(unit_id) {
      return this.units.find((res) => res.id === unit_id);
    },
    findItem(item_id) {
      let data = this.itemSearchData.filter((res) => {
        if (res.id === item_id) return res;
      });
      return data[0];
    },
    getVoucherTypes() {
      this.$rest.get('/api/journal-type?prType=purchase').then((res) => {
        this.voucherTypes = res.data.data
          .filter((item) => item.name === 'Main')
          .map((res) => {
            this.form.voucher_type_id = res.id;
            return {
              value: res.id,
              text: res.name,
            };
          });
      });
    },
    // discountPercentage() {
    //   if (this.form.discount_in_percentage > 100)
    //     this.form.discount_in_percentage = 100;
    //   this.form.discount_in_amount = (
    //     (this.sub_total * this.form.discount_in_percentage) /
    //     100
    //   ).toFixed(2);
    //   this.calculation();
    // },
    // discountAmount() {
    //   if (this.sub_total < this.form.discount_in_amount)
    //     this.form.discount_in_amount = this.sub_total;
    //   this.form.discount_in_percentage = (
    //     (this.form.discount_in_amount / this.sub_total) *
    //     100
    //   ).toFixed(2);
    //   this.calculation();
    // },
    discountPercentage() {
      if (this.form.discount_in_percentage > 100)
        this.form.discount_in_percentage = 100;
      this.form.discount_in_amount =
        (this.subTotalCompute * this.form.discount_in_percentage) / 100;
      this.getVatAmount();
    },
    discountAmount() {
      if (this.subTotalCompute < this.form.discount_in_amount)
        this.form.discount_in_amount = this.subTotalCompute;
      this.form.discount_in_percentage =
        (this.form.discount_in_amount / this.subTotalCompute) * 100;
      this.getVatAmount();
    },
    calculationbackup() {
      this.sub_total = 0;
      this.taxable_amount = 0;
      this.non_taxable_amount = 0;
      this.taxable_item_count = 0;
      this.non_taxable_item_count = 0;
      this.form.item_data.map((res) => {
        this.sub_total += parseFloat(res.amount);
        if (res.is_taxable) {
          this.taxable_item_count++;
          this.taxable_amount += parseFloat(res.amount);
        }
        if (!res.is_taxable) {
          this.non_taxable_amount += parseFloat(res.amount);
          this.non_taxable_item_count++;
        }
      });
      let individual_discount_amount = (
        parseFloat(this.form.discount_in_amount) / this.form.item_data.length
      ).toFixed(2);
      let discount_for_taxable_amount = (
        this.taxable_item_count * individual_discount_amount
      ).toFixed(2);
      let discount_for_non_taxable_amount = (
        this.non_taxable_item_count * individual_discount_amount
      ).toFixed(2);
      this.taxable_amount_after_discount = (
        parseFloat(this.taxable_amount) -
        parseFloat(discount_for_taxable_amount)
      ).toFixed(2);
      if (['', 'NaN', null].includes(this.taxable_amount_after_discount)) {
        this.taxable_amount_after_discount = 0;
      }
      this.non_taxable_amount_after_discount = (
        parseFloat(this.non_taxable_amount) - discount_for_non_taxable_amount
      ).toFixed(2);
      if (['', 'NaN', null].includes(this.non_taxable_amount_after_discount)) {
        this.non_taxable_amount_after_discount = 0;
      }
      this.tax_amount = (
        parseFloat(this.taxable_amount_after_discount) * 0.13
      ).toFixed(2);
      this.total_amount = (
        parseFloat(this.taxable_amount_after_discount) +
        parseFloat(this.non_taxable_amount_after_discount) +
        parseFloat(this.tax_amount)
      ).toFixed(2);
      if (['', NaN, null].includes(this.tax_amount)) this.tax_amount = 0;
      if (['', NaN, null].includes(this.total_amount)) this.total_amount = 0;
    },
    calculation() {
      this.sub_total = 0;
      this.taxable_amount = 0;
      this.non_taxable_amount = 0;
      this.taxable_item_count = 0;
      this.non_taxable_item_count = 0;
      this.tax_amount = 0;
      this.form.item_data.map((res) => {
        this.sub_total += parseFloat(res.amount);
      });
      let amountAfterDiscount = this.sub_total - this.form.discount_in_amount;
      if (this.vatCheckBox) {
        let taxable_amount = 0;
        this.form.item_data.map((res) => {
          if (res.is_taxable === 1) {
            taxable_amount +=
              (res.amount -
                (this.form.discount_in_percentage * res.amount) / 100) *
              0.13;
          }
        });
        this.total_amount = amountAfterDiscount + taxable_amount;
      } else {
        this.total_amount = amountAfterDiscount;
      }
    },
    addItemdataReset() {
      this.addItemData = {
        warehouse_id: '',
        is_taxable: 0,
        item_id: '',
        qty: 0,
        rate: 0,
        amount: 0,
        item_name: '',
        warehouse_name: '',
      };
    },
    fillAddItemData(index, item) {
      this.form.item_data[index].qty = item.qty;
      this.form.item_data[index].rate = item.rate;
      this.form.item_data[index].amount = item.amount;
      this.form.item_data[index].warehouse_id = item.warehouse_id;
      this.form.item_data[index].warehouse_name = item.warehouse_name;
      this.form.item_data[index].is_taxable = item.is_taxable;
      this.form.item_data[index].unit_id = item.unit_id;
      this.form.item_data[index].item_id = item.item_id;
    },
    getBillDate() {
      this.$rest
        .get('/api/account/inventory/purchase/bill-no-date')
        .then((res) => {
          this.form.bill_date = res.data.bill_date;
          this.billNumber = res.data.bill_no;
        });
    },
    getWarehouse() {
      this.$rest.get('/api/account/inventory/warehouse').then(({ data }) => {
        this.warehouse = [];
        this.warehouseData = data.data;
        data.data.map((item) => {
          this.warehouse.push({ value: item.id, text: item.name });
        });
      });
    },
    getBranch() {
      this.$rest
        .get('/api/account/inventory/branch?rowsPerPage=100')
        .then(({ data }) => {
          this.branchData = data.data;
          data.data.map((item) => {
            this.branch.push({ value: item.id, text: item.name });
          });
        });
    },
    getPurcaseItem() {
      this.$rest
        .get('api/account/inventory/purchase?purchaseType=latest')
        .then(({ data }) => {
          this.latestPurchaseItem = data.data;
        });
    },
    onPickFile() {
      this.$refs.fileInput.click();
    },
    onFilePicked(event) {
      const files = event.target.files;
      if (files[0] !== undefined) {
        this.form.fileName = files[0].name;
        // Check validity of file
        if (this.form.fileName.lastIndexOf('.') <= 0) {
          return;
        }

        this.form.bill_img = files[0];
        // If valid, continue
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener('load', () => {
          this.url = fr.result;
          this.fileObject = files[0]; // this is an file that can be sent to server...
        });
      } else {
        this.fileName = '';
        this.fileObject = null;
        this.url = '';
      }
    },
    selectItemDataCheckbok(item) {
      if (this.item_data_checkbox.indexOf(item) > -1)
        this.item_data_checkbox.splice(
          this.item_data_checkbox.indexOf(item),
          1
        );
      else this.item_data_checkbox.push(item);
      this.discountPercentage();
      this.discountAmount();
    },
    getVatAmount() {
      let taxable_amount = 0;
      if (this.vatCheckBox) {
        this.item_data_checkbox.map((res) => {
          if (res.is_taxable === 1) {
            if (this.form.discount_in_percentage > 0) {
              taxable_amount +=
                (res.amount -
                  (this.form.discount_in_percentage * res.amount) / 100) *
                0.13;
            } else {
              taxable_amount += res.amount * 0.13;
            }
          }
        });
        this.tax_amount = taxable_amount;
      } else {
        this.tax_amount = 0;
      }
    },
    removeFile() {
      this.form.fileName = '';
    },
  },
};
</script>
<style lang="scss">
.itemWrap {
  margin: 0px;
  border: 1px solid #ccc;
  padding: 20px;
}
.discountText {
  height: 25px;
  border: 1px solid #bbb;
  text-align: left;
  /*width: 75%;*/
  width: 100%;
}
/*.discountPer {*/
/*height: 25px;*/
/*border: 1px solid #bbb;*/
/*width: 105%;*/
/*!*width: 60px;*!*/
/*!* padding-bottom: 0px; *!*/
/*text-align: center;*/
/*!*width: 109px;*!*/
/*!*margin-right: 40px;*!*/
/*}*/
.dis_form_container {
  /*margin-top: 20px;*/
  /*background-color: red;*/
  display: flex;
  .dis_form_label {
    flex: 2;
    padding: 15px;
    /*border-right: 1px dashed #eee;*/
    /*background-color: green;*/
  }
  .dis_form_input {
    flex: 1;
    padding: 5px;
    /*background-color: orange;*/
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
.footerRow {
  height: 25px !important;
  border: none !important;
}
.footerRowNarration {
  border: none !important;
}
.footerTextarea {
  border: 1px solid #bbb;
  text-align: left;
  /*width: 75%;*/
  width: 100%;
}
.chipH {
  height: 20px;
}
.tb tr:last-child {
  border-bottom: 1px solid black;
}
.span-title {
  color: red;
  padding-top: 15px;
  padding-right: 5px;
  padding-bottom: 15px;
  padding-left: 15px;
}
.span-qty {
  color: green;
}
.voucher-no {
  color: green;
}
.bill-no {
  margin-left: 25px;
  color: #666;
  margin-bottom: 10px;
}
</style>
